<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-popup title="Date de depôt" :active.sync="popupDepotFacture">
        <div class="vx-row">
          <div class="vx-col w-full">
            <label for="dateDebut">Date</label>
            <datepicker  format="d MMMM yyyy" v-model="dateDepot"></datepicker>
          </div>
          <div class="vx-col w-full mt-5">
            <label for="observationDepot">Observation</label>
            <vs-textarea name="observationDepot" rows="6" :value="observationDepot" @change="observationDepot=$event.target.value" />
          </div>
        </div>
          <br><br><br><br><br>
        <div class="vx-row mt-5">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="DepotFactureAssurance">VALIDER</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="popupDepotFacture = false;">ANNULER</vs-button>
          </div>
        </div>
      </vs-popup>
      <vs-popup fullscreen title="Factures client" :active.sync="popupListeFactureClient">

        <div class="vx-row">
          <div v-if="donnees" class="vx-col w-full">
            <vs-table  noDataText="" pagination max-items="50" stripe search :data="donnees.facturesClient">
              <template slot="header">

              </template>
              <template slot="thead">
                <vs-th v-if="donnees.champDate">
                  DATE
                </vs-th>
                <vs-th v-if="donnees.champMatricule" sort-key="numero">
                  MATRICULE
                </vs-th>
                <vs-th v-if="donnees.champNomPrenom">
                  NOM & PRENOM
                </vs-th>
                <vs-th v-if="donnees.champMonture" sort-key="Nom">
                  MONTURE
                </vs-th>
                <vs-th v-if="donnees.champVerre" sort-key="Nom">
                  VERRES
                </vs-th>
                <vs-th v-if="donnees.champRemise" sort-key="Nom">
                  REMISE
                </vs-th>
                <vs-th v-if="donnees.champTotal">
                  TOTAL
                </vs-th>
                <vs-th v-if="donnees.champClient">
                  CLIENT
                </vs-th>
                <vs-th v-if="donnees.champAssurance">
                  ASSURANCE
                </vs-th>
                <vs-th>
                  Télécharger
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td v-if="donnees.champDate">
                    {{tr.DateVente | moment("calendar", "July 10 2011")}}
                  </vs-td>
                  <vs-td v-if="donnees.champMatricule" :data="data[indextr].matricule">
                    {{tr.matricule}}
                  </vs-td>
                  <vs-td v-if="donnees.champNomPrenom" :data="data[indextr].patient">
                    {{tr.patient}}
                  </vs-td>
                  <vs-td v-if="donnees.champMonture" :data="data[indextr].PrixMont">
                    {{moneyFormatter((tr.PrixMont ? tr.PrixMont : 0))}}
                  </vs-td>
                  <vs-td v-if="donnees.champVerre">
                    {{moneyFormatter(((tr.PrixVerG ? tr.PrixVerG : 0) + (tr.PrixVerD ? tr.PrixVerD : 0) + (tr.prixPhotogray ? tr.prixPhotogray : 0) + (tr.prixAntiReflet ? tr.prixAntiReflet : 0) + (tr.prixAntiRayure ? tr.prixAntiRayure : 0) + (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}
                  </vs-td>
                  <vs-td v-if="donnees.champRemise" :data="data[indextr].RemiseCom">
                    {{moneyFormatter(tr.RemiseCom ? tr.RemiseCom : 0)}}
                  </vs-td>
                  <vs-td v-if="donnees.champTotal">
                    {{moneyFormatter(totalNet(tr.PrixMont, tr.PrixVerG, tr.PrixVerD, tr.RemiseCom, tr.prixPhotogray, tr.prixAntiReflet, tr.prixAntiRayure, tr.prixAutreTraitement))}}
                  </vs-td>
                  <vs-td v-if="donnees.champClient">
                    {{moneyFormatter(calculParClient((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.TickForf ? tr.TickForf: 0), tr.prixPhotogray, tr.prixAntiReflet, tr.prixAntiRayure, tr.prixAutreTraitement))}}
                  </vs-td>
                  <vs-td v-if="donnees.champAssurance">
                    {{moneyFormatter(calculParAssurance((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0)), calculParClient((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.TickForf ? tr.TickForf: 0), tr.prixPhotogray, tr.prixAntiReflet, tr.prixAntiRayure, tr.prixAutreTraitement), tr.prixPhotogray, tr.prixAntiReflet, tr.prixAntiRayure, tr.prixAutreTraitement)}}
                  </vs-td>
                  <vs-td>
                    <a  @click="factureAssuranceClientDetailPdfGenerator(tr._id)">Générer </a>
                    <a  v-if="tr.pdf" :href="tr.pdf" target="_blank">/ Télécharger</a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

          </div>
        </div>
      </vs-popup>
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table  noDataText="" pagination max-items="50" stripe search :data="factures">
              <template slot="header">
              </template>
              <template slot="thead">
                <vs-th>
                  Période
                </vs-th>
                <vs-th sort-key="numero">
                  Assurance
                </vs-th>
                <vs-th>
                  Part assurance
                </vs-th>
                <vs-th sort-key="Nom">
                  Part client
                </vs-th>
                <vs-th sort-key="Nom">
                  Nombre vente
                </vs-th>
                <vs-th>
                  Deposer
                </vs-th>
                <vs-th>
                  Facture
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td>
                    {{tr.PeriodeDebut | moment("calendar", "July 10 2011")}} - {{tr.PeriodeFin | moment("calendar", "July 10 2011")}}
                  </vs-td>
                  <vs-td :data="data[indextr].NomAssurance">
                    {{tr.NomAssurance}}
                  </vs-td>
                  <vs-td :data="data[indextr].MontantAssurance">
                    {{moneyFormatter(tr.MontantAssurance ? tr.MontantAssurance : 0)}}
                  </vs-td>
                  <vs-td :data="data[indextr].MontantClient">
                    {{moneyFormatter(tr.MontantClient ? tr.MontantClient : 0)}}
                  </vs-td>
                  <vs-td :data="data[indextr].facturesClient.length">
                    {{tr.facturesClient.length}}
                  </vs-td>
                  <vs-td>
                    <span v-if="tr.depot === false">
                      <vs-chip transparent color="danger">
                        Non
                      </vs-chip>
                    </span>
                    <span v-if="tr.depot === true">
                      <vs-chip transparent color="primary">
                        Oui
                      </vs-chip>
                    </span>
                  </vs-td>
                  <vs-td>
                    <a  @click="factureAssurancePdfGenerator(tr)">Générer </a>
                    <a  v-if="tr.pdf" :href="tr.pdf" target="_blank">/ Télécharger</a>
                  </vs-td>
                  <vs-td>
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon title="Depôt facture" icon="SendIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showDepotFactureAssurance(tr)" />
                      <feather-icon title="Détails" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showListeFactureClient(tr)" />
                    </div>
                  </vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td>

                  </vs-td>
                  <vs-td>

                  </vs-td>
                  <vs-td>
                    = {{moneyFormatter(totalPartAssurance)}} FCFA
                  </vs-td>
                  <vs-td>
                    = {{moneyFormatter(totalPartClient)}} FCFA
                  </vs-td>
                  <vs-td>
                    = {{totalNbreVente}}
                  </vs-td>
                  <vs-td>
                  </vs-td>
                  <vs-td>
                  </vs-td>
                  <vs-td>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import * as lang from 'vuejs-datepicker/src/locale'
export default {
  components: {
    Datepicker
  },
  data () {
    return {
      popupDepotFacture: false,
      idFacture: null,
      dateDepot: null,
      observationDepot: null,

      donnees: null,
      popupListeFactureClient: false,

      language: 'fr',
      languages: lang
    }
  },
  computed: {
    factures () {
      return this.$store.state.facturation.factures_assurance_a_deposer
    },
    totalPartAssurance () {
      const total = this.factures.length > 0 ? this.factures.reduce((acc, facture) => {
        return acc + (facture.MontantAssurance ? facture.MontantAssurance : 0)
      }, 0) : 0
      return total
    },
    totalPartClient () {
      const total = this.factures.length > 0 ? this.factures.reduce((acc, facture) => {
        return acc + (facture.MontantClient ? facture.MontantClient : 0)
      }, 0) : 0
      return total
    },
    totalNbreVente () {
      const total = this.factures.length > 0 ? this.factures.reduce((acc, facture) => {
        return acc + (facture.facturesClient.length ? facture.facturesClient.length : 0)
      }, 0) : 0
      return total
    }
  },

  methods: {
    totalNet (PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) {
      return ((((+PrixMont ? +PrixMont : 0) + (+PrixVerG ? +PrixVerG : 0) + (+PrixVerD ? +PrixVerD : 0) + (prixPhotogray ? +prixPhotogray : 0) + (prixAntiReflet ? +prixAntiReflet : 0) + (prixAntiRayure ? +prixAntiRayure : 0) + (prixAutreTraitement ? +prixAutreTraitement : 0)) - (RemiseCom ? RemiseCom : 0)))
    },
    calculParClient (PrixMont, PrixVerG, PrixVerD, RemiseCom, plafon, ticket, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) {
      let partClient = null

      if (this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) >= plafon) {
        partClient = this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) - +plafon
      } else if (ticket <= 100) {
        partClient = Math.round(((this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) * ticket) / 100))
      } else {

        partClient = ticket
      }

      return partClient
    },
    calculParAssurance (PrixMont, PrixVerG, PrixVerD, RemiseCom, plafon, PartClient, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) {
      let PartAssu = null
      const factureNet = this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement)
      if (factureNet >= plafon) {
        PartAssu = plafon
      } else {
        PartAssu = Math.round((+factureNet - +PartClient))
      }
      return PartAssu
    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    showListeFactureClient (data) {
      this.donnees = data
      this.idFacture = data._id
      this.popupListeFactureClient = true
    },
    showDepotFactureAssurance (data) {
      this.idFacture = data._id
      this.popupDepotFacture = true
    },
    DepotFactureAssurance () {
      const payload = {
        dateDepot: this.dateDepot,
        observationDepot: this.observationDepot,
        id: this.idFacture
      }
      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('facturation/DepotFactureAssurance', payload)
        .then(() => {
          this.id = null
          this.dateDepot = null
          this.popupDepotFacture = false
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })

    },
    factureAssurancePdfGenerator (facture) {
      console.log('data:', facture)
      const payload = {
        data: facture
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/factureAssurancePdfGenerator', payload)
        .then((response) => {
          this.$vs.loading.close()
          window.open(response.data.pdf)
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    factureAssuranceClientDetailPdfGenerator (id) {
      const payload = {
        idFactureGroupe: this.idFacture,
        idFactureClient: id
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/factureAssuranceClientDetailPdfGenerator', payload)
        .then((response) => {
          this.$vs.loading.close()
          window.open(response.data.url)
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getFactureAssuranceADepose () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/getFactureAssuranceADepose')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    }
  },
  created () {
    this.getFactureAssuranceADepose()
  }
}
</script>

<style scoped>
.customtable, .customth, .customtd {
  border: 0.5px solid black;
  border-collapse: collapse;
  /* font-family: 'Times New Roman'; */
  font-size: 12px;
}

.customtd {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}
</style>

